





























import { Component, Vue, Prop } from "vue-property-decorator";
@Component({})
export default class HdrReportHospitalTransfer extends Vue {
  @Prop()
  private visible: any;
  @Prop()
  private canChooseHospitals: any;
  @Prop()
  private alreadySelectedHospitals: any;
  private selectedHospitals: any[] = [];
  private get ifVisible() {
    return this.visible;
  }
  private set ifVisible(val) {
    this.$emit("update:visible", val);
  }
  /**
   * @description 取消
   */
  private cancel() {
    this.ifVisible = false;
  }
  /**
   * @description 确认选择
   */
  private confirm() {
    this.$emit("confirm", this.selectedHospitals);
    this.ifVisible = false;
  }
  /**
   * @description 初始化
   */
  private mounted() {
    if (this.alreadySelectedHospitals) {
      this.alreadySelectedHospitals.forEach((h: any) => {
        this.selectedHospitals.push(h._id);
      });
    }
  }
}
