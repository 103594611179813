




























































































































































































































































































































































































































































































































































































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import {
  PostEva,
  PostAll,
  PostStatsMonth,
  PostStatsHospital,
  PostStatsPeople,
  PostStatsNeirong,
  PostStatsTizhi,
  PostStatsXinli,
  PostStatsPopulation,
  GetFilterOption,
} from "@/request/differences";
import MainHeader from "@/components/main-header/main-header.vue";
import NavLeft from "@/components/nav-left/nav-left.vue";
import TimeSelect from "@/components/time-select.vue";
import HospitalTransfer from "./hospital-transfer.vue";
import Empty from "@/components/empty.vue";
import SelectSizhenshebei from "@/components/select-sizhenshebei.vue";
@Component({
  components: {
    MainHeader,
    NavLeft,
    TimeSelect,
    HospitalTransfer,
    Empty,
    SelectSizhenshebei,
  },
})
export default class Name extends Vue {
  private timeOpt: any = ["全部时间", "最近1年", "最近6个月", "最近1个月"];
  private timeOpt2: any = ["全部时间", "最近1年", "最近30天", "最近7天"];
  private data: any = {
    服务人次统计概览: {
      机构总体情况: {
        totalCount: "",
      },
    },
    测评量统计概览: {
      机构总体情况: {},
      机构月测评趋势图: {
        data: [],
      },
      各机构测评量分布状况: {
        柱状图: {
          data: [],
        },
      },
    },
    测评内容统计: {
      测评内容总体情况: {
        data: [],
      },
      中医体质情况: {
        data: [],
      },
      中医证型情况: {
        data: [],
      },
      居民心理类型情况: {
        居民心理类型情况: {},
        table: {
          data: [],
        },
      },
    },
    人口统计: {
      地区分布: {
        data: [],
      },
      年龄分布: {
        data: [],
      },
      性别分布: {
        data: [],
      },
    },
  };
  private filters: any = {
    服务人次统计概览: {
      机构月服务人次趋势图: {
        统计年限: "",
      },
    },
    测评量统计概览: {
      机构月测评趋势图: {
        统计年限: "",
      },
      各机构测评量分布状况: {
        统计时间: "全部时间",
        人群: "全部",
        开始时间: "",
        结束时间: "",
      },
      重点人群测评量分布状况: {
        统计时间: "全部时间",
        人群: "全部",
        开始时间: "",
        结束时间: "",
      },
    },
    测评内容统计: {
      测评内容总体情况: {
        统计时间: "全部时间",
        开始时间: "",
        结束时间: "",
      },
      中医体质情况: {
        统计时间: "全部时间",
        测评类型: "中医体质辨识",
        开始时间: "",
        结束时间: "",
      },
      中医证型情况: {
        统计时间: "全部时间",
        测评类型: "高血压辅助辨证",
        开始时间: "",
        结束时间: "",
      },
      居民心理类型情况: {
        统计时间: "全部时间",
        开始时间: "",
        结束时间: "",
      },
    },
  };
  private yuequshi: any = {};
  private jigouqushi: any = {};
  private fuwuliangfenbuBingtu: any = {};
  private zhongdianPies: any = [];
  private zongtiPie: any = {};
  private tizhiOption: any = {};
  private xinliOption: any = {};
  private zhengxingOption: any = {};
  private juminBar: any = {};
  private ageOptions: any = {};
  private selectedHospitals: any[] = [];
  private selectedDoctors: any[] = [];
  private canChooseHospitals: any[] = [];
  private ifShowHospitalTransfer: boolean = false;
  private renqunOptions: any = [
    "全部",
    "老年人",
    "儿童",
    "高血压",
    "糖尿病",
    "女性",
    "亚健康",
  ];
  private colors: any = [
    "#FFA7CC",
    "#46AAAA",
    "#FFAA6A",
    "#A185D3",
    "#82D1F1",
    "#EC7E68",
    "#F6C63C",
    "#7784A2",
    "#75DDB5",
    "#759FFC",
    "#FFA7CC",
    "#46AAAA",
    "#FFAA6A",
    "#A185D3",
    "#82D1F1",
    "#EC7E68",
    "#F6C63C",
    "#7784A2",
    "#75DDB5",
    "#759FFC",
    "#FFA7CC",
    "#46AAAA",
    "#FFAA6A",
    "#A185D3",
    "#82D1F1",
    "#EC7E68",
    "#F6C63C",
    "#7784A2",
    "#75DDB5",
    "#759FFC",
    "#FFA7CC",
    "#46AAAA",
    "#FFAA6A",
    "#A185D3",
    "#82D1F1",
    "#EC7E68",
    "#F6C63C",
    "#7784A2",
    "#75DDB5",
    "#759FFC",
  ];
  private pieColors: any = [
    "#50A7F9",
    "#69D688",
    "#FEDB57",
    "#BA95F8",
    "#F4AF6E",
    "#F88AA9",
  ];
  private tizhiTypes: any[] = [
    "中医体质辨识",
    "四诊合参体质辨识",
    "0-6岁儿童体质辨识",
    "7-12岁儿童体质辨识",
    "老年人体质辨识",
    "备孕女性健康状态测评",
    "孕期女性健康状态测评",
    "产后女性健康状态测评",
    "围绝经期女性健康状态测评",
  ];
  private zhengxingTypes: any[] = ["高血压辅助辨证", "糖尿病辅助辨证"];
  private zhongdianPieBase: any = {
    grid: {},
    tooltip: {
      formatter: (params: any) => {
        if (params.dataIndex === 1) {
          return "";
        } else {
          return params.name + ":" + params.percent + "%";
        }
      },
    },
    series: [
      {
        type: "pie",
        data: [],
        label: {
          position: "center",
          formatter: (params: any) => {
            if (params.dataIndex === 1) {
              return "";
            } else {
              return params.value;
            }
          },
          fontSize: 26,
        },
        radius: ["50%", "70%"],
      },
    ],
  };
  private get user() {
    return this.$store.state.user;
  }
  private isHasValue(arr: any) {
    let num = 0;
    arr.forEach((ele: any) => {
      num += ele.value;
    });
    return num;
  }
  private get isHasFuwurenci() {
    const data = this.data["服务人次统计概览"]["机构总体情况"];
    if (!data) {
      return false;
    }
    return (
      data.aprCount ||
      data.augCount ||
      data.decCount ||
      data.febCount ||
      data.janCount ||
      data.julCount ||
      data.junCount ||
      data.marCount ||
      data.mayCount ||
      data.novCount ||
      data.octCount ||
      data.septCount
    );
  }
  private get isHasYuequshi() {
    const data = this.data["测评量统计概览"]["机构月测评趋势图"];
    let num = 0;
    data.data.forEach((ele: any) => {
      num += ele.value;
    });
    return num;
  }
  private get selectedHospitalLabels() {
    if (this.selectedDoctors.length === 0) {
      return "全部机构";
    } else {
      const labels: any[] = [];
      this.selectedDoctors.forEach((h: any) => {
        labels.push(h["account_id"]);
      });
      return labels.join("，");
    }
  }
  private get timeOptions() {
    const arr: any = [];
    const currentYear = new Date().getFullYear();
    Array.of(0, 1, 2, 3, 4).forEach((i: any) => {
      arr.push(currentYear - i);
    });
    this.filters["服务人次统计概览"]["机构月服务人次趋势图"]["统计年限"] =
      arr[0];
    this.filters["测评量统计概览"]["机构月测评趋势图"]["统计年限"] = arr[0];
    return arr;
  }
  private get center() {
    return this.$store.state.center;
  }
  private cancel() {
    this.ifShowHospitalTransfer = false;
  }
  private updataShebeiData(e: any) {
    this.selectedDoctors = e;
    let arr: any = [];
    e.forEach((ele: any) => {
      const index = arr.findIndex((val: any) => {
        return ele.hospital_id === val.hospital_id;
      });
      if (index == -1) {
        arr.push({
          _id: ele.hospital_id,
          hospital_id: ele.hospital_id,
          医院名称: ele["医院名称"],
          field: ele["医院名称"],
        });
      }
    });
    this.selectedHospitals = arr;
    this.cancel();
    this.refresh();
  }
  /**
   * @description 跳转数据大屏
   */
  private jumpDapin() {
    this.$router.push({
      path: "/main/datacenter/board/daping",
    });
  }
  /**
   * @description 重置
   */
  private reset() {
    this.selectedHospitals = [];
    this.selectedDoctors = [];
    this.refresh();
  }
  /**
   * @description 统计机构改变
   */
  private hospitalChanged(ids: any) {
    this.selectedHospitals = [];
    ids.forEach((id: any) => {
      const targetHospital = this.canChooseHospitals.find((h: any) => {
        return h._id === id;
      });
      if (targetHospital) {
        this.selectedHospitals.push(targetHospital);
      }
    });
    this.refresh();
  }
  /**
   * @description 获取可选医院列表
   */
  private fetchHospitals() {
    const load = this.$loading({ text: "加载中" });
    return new Promise((resolve: any) => {
      const params = {
        search: "",
        type: "机构",
      };
      GetFilterOption(this, params).then((data: any) => {
        this.canChooseHospitals = data;
        load.close();
        resolve();
      });
    });
  }
  /**
   * @description 计算各机构服务量分布的背景
   */
  private calBackground(index: any, item: any, first: any) {
    const max = first.value;
    if (!this.$refs.zongliang as any) {
      return {};
    }
    // 总共40个颜色，除40取余
    const style: any = {};
    style.backgroundColor = this.colors[index % 40];
    if (!style.backgroundColor) {
      style.backgroundColor = "#000000";
    }
    const w = (item.value / max) * (this.$refs.zongliang as any).offsetWidth;
    if (w > 0 && w < 40) {
      style.width = 40 + "px";
    } else {
      style.width = w + "px";
    }
    // style.width =
    //   (item.value / max) * (this.$refs.zongliang as any).offsetWidth +
    //   40 +
    //   "px";
    return style;
  }
  /**
   * @description 服务人次统计概览
   */
  private getEva() {
    const ids: any = [];
    this.selectedHospitals.forEach((h: any) => {
      ids.push(h._id);
    });
    const doctors: any = [];
    this.selectedDoctors.forEach((h: any) => {
      doctors.push(h.doctor_id);
    });
    const params: any = {
      hospital_ids: ids,
      doctor_id: doctors,
      year: this.filters["服务人次统计概览"]["机构月服务人次趋势图"][
        "统计年限"
      ],
    };
    PostEva(this, params).then((res: any) => {
      // if (!res) {
      //   this.$message.error("服务人次计算错误");
      //   return;
      // }
      this.data["服务人次统计概览"]["机构总体情况"] = res;
      const title = [
        "一月",
        "二月",
        "三月",
        "四月",
        "五月",
        "六月",
        "七月",
        "八月",
        "九月",
        "十月",
        "十一月",
        "十二月",
      ];
      const data = [
        {
          value: res["janCount"],
          name: "一月",
        },
        {
          value: res["febCount"],
          name: "二月",
        },
        {
          value: res["marCount"],
          name: "三月",
        },
        {
          value: res["aprCount"],
          name: "四月",
        },
        {
          value: res["mayCount"],
          name: "五月",
        },
        {
          value: res["junCount"],
          name: "六月",
        },
        {
          value: res["julCount"],
          name: "七月",
        },
        {
          value: res["augCount"],
          name: "八月",
        },
        {
          value: res["septCount"],
          name: "九月",
        },
        {
          value: res["octCount"],
          name: "十月",
        },
        {
          value: res["novCount"],
          name: "十一月",
        },
        {
          value: res["decCount"],
          name: "十二月",
        },
      ];
      this.yuequshi = {
        tooltip: {
          show: true,
          trigger: "item",
        },
        grid: {
          left: "80",
          right: "2%",
          top: "40",
          bottom: "40",
        },
        xAxis: {
          type: "category",
          axisLabel: {
            color: "rgba(0,0,0,0.45)",
          },
          axisLine: {
            lineStyle: {
              color: "rgba(0,0,0,0.45)",
            },
          },
          data: title,
        },
        yAxis: {
          name: "服务人次",
          type: "value",
          axisLine: {
            show: true,
          },
        },
        series: [
          {
            type: "bar",
            data,
            itemStyle: {
              color: "#6C99F6",
            },
          },
        ],
      };
    });
  }
  /**
   * @description 测评量统计概览-机构总体情况
   */
  private getAll() {
    const ids: any = [];
    this.selectedHospitals.forEach((h: any) => {
      ids.push(h._id);
    });
    const doctors: any = [];
    this.selectedDoctors.forEach((h: any) => {
      doctors.push(h.doctor_id);
    });
    const params: any = {
      hospital_ids: ids,
      doctor_id: doctors,
    };
    PostAll(this, params).then((res: any) => {
      this.data["测评量统计概览"]["机构总体情况"] = res;
    });
  }
  /**
   * @description 测评量统计概览-机构月测评趋势图
   */
  private getStatusMouth() {
    const ids: any = [];
    this.selectedHospitals.forEach((h: any) => {
      ids.push(h._id);
    });
    const doctors: any = [];
    this.selectedDoctors.forEach((h: any) => {
      doctors.push(h.doctor_id);
    });
    const params: any = {
      hospital_ids: ids,
      doctor_id: doctors,
      year: this.filters["测评量统计概览"]["机构月测评趋势图"]["统计年限"],
    };
    PostStatsMonth(this, params).then((res: any) => {
      this.data["测评量统计概览"]["机构月测评趋势图"] = res;
      this.jigouqushi = {
        tooltip: {
          show: true,
          trigger: "item",
        },
        grid: {
          left: "80",
          right: "2%",
          top: "40",
          bottom: "40",
        },
        xAxis: {
          type: "category",
          axisLabel: {
            color: "rgba(0,0,0,0.45)",
          },
          axisLine: {
            lineStyle: {
              color: "rgba(0,0,0,0.45)",
            },
          },
          data: res.title,
        },
        yAxis: {
          name: "测评量",
          type: "value",
          axisLine: {
            show: true,
          },
        },
        series: [
          {
            type: "bar",
            data: res.data,
            itemStyle: {
              color: "#6C99F6",
            },
          },
        ],
      };
    });
  }
  /**
   * @description 测评量统计概览-各机构测评量分布状况-统计时间改变
   */
  private jigouTimeChange(e: any) {
    this.filters["测评量统计概览"]["各机构测评量分布状况"] = e;
    this.getStatsHospital();
  }
  /**
   * @description 测评量统计概览-各机构测评量分布状况
   */
  private getStatsHospital() {
    const ids: any = [];
    this.selectedHospitals.forEach((h: any) => {
      ids.push(h._id);
    });
    const doctors: any = [];
    this.selectedDoctors.forEach((h: any) => {
      doctors.push(h.doctor_id);
    });
    const params: any = {
      hospital_ids: ids,
      doctor_id: doctors,
      人群: this.filters["测评量统计概览"]["各机构测评量分布状况"]["人群"],
      统计时间:
        this.filters["测评量统计概览"]["各机构测评量分布状况"]["统计时间"],
    };
    if (params["统计时间"] === "自定义") {
      delete params["统计时间"];
      params["开始时间"] =
        this.filters["测评量统计概览"]["各机构测评量分布状况"]["开始时间"];
      params["结束时间"] =
        this.filters["测评量统计概览"]["各机构测评量分布状况"]["结束时间"];
    }
    PostStatsHospital(this, params).then((res: any) => {
      this.data["测评量统计概览"]["各机构测评量分布状况"] = res;
      res["饼图"]["data"].map((item: any) => {
        item.name = item.title;
      });
      this.fuwuliangfenbuBingtu = {
        grid: {},
        tooltip: {},
        color: this.colors,
        series: [
          {
            type: "pie",
            data: res["饼图"].data,
            radius: ["0%", "70%"],
          },
        ],
      };
    });
  }
  /**
   * @description 测评量统计概览-重点人群测评量分布状况-统计时间改变
   */
  private renqunTimeChange(e: any) {
    this.filters["测评量统计概览"]["重点人群测评量分布状况"] = e;
    this.getStatsPeople();
  }
  /**
   * @description 测评量统计概览-重点人群测评量分布状况
   */
  private getStatsPeople() {
    const ids: any = [];
    this.selectedHospitals.forEach((h: any) => {
      ids.push(h._id);
    });
    const doctors: any = [];
    this.selectedDoctors.forEach((h: any) => {
      doctors.push(h.doctor_id);
    });
    const params: any = {
      hospital_ids: ids,
      doctor_id: doctors,
      统计时间:
        this.filters["测评量统计概览"]["重点人群测评量分布状况"]["统计时间"],
    };
    if (params["统计时间"] === "自定义") {
      delete params["统计时间"];
      params["开始时间"] =
        this.filters["测评量统计概览"]["重点人群测评量分布状况"]["开始时间"];
      params["结束时间"] =
        this.filters["测评量统计概览"]["重点人群测评量分布状况"]["结束时间"];
    }
    PostStatsPeople(this, params).then((res: any) => {
      this.zhongdianPies = [];
      res.data.forEach((item: any, index: any) => {
        const tempPie: any = JSON.parse(JSON.stringify(this.zhongdianPieBase));
        tempPie.tooltip = {
          formatter: (p: any) => {
            if (p.dataIndex === 1) {
              return "";
            } else {
              return p.data.title + ":" + p.percent + "%";
            }
          },
        };
        // item.name = item.title + "\n" + item.value;
        const dataObj: any = JSON.parse(JSON.stringify(item));
        tempPie.title = {
          text: "{name|" + item.title + "}\n{val|" + item.value + "}",
          top: "center",
          left: "center",
          textStyle: {
            rich: {
              name: {
                fontSize: 14,
                fontWeight: "normal",
                color: "#666666",
                padding: [10, 0],
              },
              val: {
                fontSize: 26,
                fontWeight: "bold",
                color: "#333333",
              },
            },
          },
        };
        tempPie.color = [this.pieColors[index], "#ECE9EC"];
        if (item.value) {
          tempPie.color = [this.pieColors[index], "#ECE9EC"];
        } else {
          tempPie.color = ["#ECE9EC", "#ECE9EC"];
        }
        tempPie.series[0].data = [
          dataObj,
          { name: "", value: dataObj.value / dataObj.ratio - dataObj.value },
        ];
        this.zhongdianPies.push(tempPie);
      });
    });
  }
  /**
   * @description 测评内容统计-测评内容总体情况-统计时间改变
   */
  private neirongTimeChange(e: any) {
    this.filters["测评内容统计"]["测评内容总体情况"] = e;
    this.getStatsNeirong();
  }
  /**
   * @description 测评内容统计-测评内容总体情况
   */
  private getStatsNeirong() {
    const ids: any = [];
    this.selectedHospitals.forEach((h: any) => {
      ids.push(h._id);
    });
    const doctors: any = [];
    this.selectedDoctors.forEach((h: any) => {
      doctors.push(h.doctor_id);
    });
    const params: any = {
      hospital_ids: ids,
      doctor_id: doctors,
      统计时间: this.filters["测评内容统计"]["测评内容总体情况"]["统计时间"],
    };
    if (params["统计时间"] === "自定义") {
      delete params["统计时间"];
      params["开始时间"] =
        this.filters["测评内容统计"]["测评内容总体情况"]["开始时间"];
      params["结束时间"] =
        this.filters["测评内容统计"]["测评内容总体情况"]["结束时间"];
    }
    PostStatsNeirong(this, params).then((res: any) => {
      this.data["测评内容统计"]["测评内容总体情况"] = res;
      res["data"].map((item: any) => {
        item.name = item.title;
      });
      this.zongtiPie = {
        color: this.pieColors,
        legend: {
          left: 5,
          orient: "vertical",
          data: res.title,
          icon: "circle",
        },
        grid: {
          width: "90%",
          right: "1%",
        },
        tooltip: {},
        series: [
          {
            type: "pie",
            data: res.data,
            radius: ["50%", "70%"],
            label: {
              show: false,
            },
            center: ["70%", "50%"],
          },
        ],
      };
    });
  }
  /**
   * @description 测评内容统计-中医体质情况-统计时间改变
   */
  private tizhiTimeChange(e: any) {
    this.filters["测评内容统计"]["中医体质情况"] = e;
    this.getStatsTizhi();
  }
  /**
   * @description 测评内容统计-中医体质情况
   */
  private getStatsTizhi() {
    const ids: any = [];
    this.selectedHospitals.forEach((h: any) => {
      ids.push(h._id);
    });
    const doctors: any = [];
    this.selectedDoctors.forEach((h: any) => {
      doctors.push(h.doctor_id);
    });
    const params: any = {
      hospital_ids: ids,
      doctor_id: doctors,
      统计时间: this.filters["测评内容统计"]["中医体质情况"]["统计时间"],
      测评类型: this.filters["测评内容统计"]["中医体质情况"]["测评类型"],
    };
    if (params["统计时间"] === "自定义") {
      delete params["统计时间"];
      params["开始时间"] =
        this.filters["测评内容统计"]["中医体质情况"]["开始时间"];
      params["结束时间"] =
        this.filters["测评内容统计"]["中医体质情况"]["结束时间"];
    }
    PostStatsTizhi(this, params).then((res: any) => {
      this.data["测评内容统计"]["中医体质情况"] = res;
      // res["data"].map((item: any) => {
      //   item.name = item.title;
      // });
      this.tizhiOption = {
        tooltip: {
          show: true,
          trigger: "item",
        },
        grid: {
          left: "80",
          right: "2%",
          top: "40",
          bottom: "40",
        },
        xAxis: {
          type: "category",
          axisLabel: {
            // color: "rgba(0,0,0,0.6)",
            rotate: 30,
          },
          axisLine: {
            lineStyle: {
              color: "rgba(0,0,0,0.5)",
            },
          },
          data: res.title,
        },
        yAxis: {
          name: "服务量",
          type: "value",
          axisLine: {
            show: true,
          },
        },
        series: [
          {
            type: "bar",
            data: res.data,
            itemStyle: {
              color: "#6C99F6",
            },
          },
        ],
      };
    });
  }
  /**
   * @description 测评内容统计-中医证型情况-统计时间改变
   */
  private zhengxingTimeChange(e: any) {
    this.filters["测评内容统计"]["中医证型情况"] = e;
    this.getStatsZhengxing();
  }
  /**
   * @description 测评内容统计-中医证型情况
   */
  private getStatsZhengxing() {
    const ids: any = [];
    this.selectedHospitals.forEach((h: any) => {
      ids.push(h._id);
    });
    const doctors: any = [];
    this.selectedDoctors.forEach((h: any) => {
      doctors.push(h.doctor_id);
    });
    const params: any = {
      hospital_ids: ids,
      doctor_id: doctors,
      统计时间: this.filters["测评内容统计"]["中医证型情况"]["统计时间"],
      测评类型: this.filters["测评内容统计"]["中医证型情况"]["测评类型"],
    };
    if (params["统计时间"] === "自定义") {
      delete params["统计时间"];
      params["开始时间"] =
        this.filters["测评内容统计"]["中医证型情况"]["开始时间"];
      params["结束时间"] =
        this.filters["测评内容统计"]["中医证型情况"]["结束时间"];
    }
    PostStatsTizhi(this, params).then((res: any) => {
      this.data["测评内容统计"]["中医证型情况"] = res;
      this.zhengxingOption = {
        tooltip: {
          show: true,
          trigger: "item",
        },
        grid: {
          left: "80",
          right: "2%",
          top: "40",
          bottom: "100",
        },
        xAxis: {
          type: "category",
          axisLabel: {
            // color: "#ccc",
            rotate: 45,
          },
          axisLine: {
            lineStyle: {
              color: "rgba(0,0,0,0.45)",
            },
          },
          data: res.title,
        },
        yAxis: {
          name: "服务量",
          type: "value",
          axisLine: {
            show: true,
          },
        },
        series: [
          {
            type: "bar",
            data: res.data,
            itemStyle: {
              color: "#6C99F6",
            },
          },
        ],
      };
    });
  }
  /**
   * @description 测评内容统计-居民心理类型情况-统计时间改变
   */
  private xinliTimeChange(e: any) {
    this.filters["测评内容统计"]["居民心理类型情况"] = e;
    this.getStatsXinli();
  }
  /**
   * @description 测评内容统计-居民心理类型情况
   */
  private getStatsXinli() {
    const ids: any = [];
    this.selectedHospitals.forEach((h: any) => {
      ids.push(h._id);
    });
    const doctors: any = [];
    this.selectedDoctors.forEach((h: any) => {
      doctors.push(h.doctor_id);
    });
    const params: any = {
      hospital_ids: ids,
      doctor_id: doctors,
      统计时间: this.filters["测评内容统计"]["居民心理类型情况"]["统计时间"],
    };
    if (params["统计时间"] === "自定义") {
      delete params["统计时间"];
      params["开始时间"] =
        this.filters["测评内容统计"]["居民心理类型情况"]["开始时间"];
      params["结束时间"] =
        this.filters["测评内容统计"]["居民心理类型情况"]["结束时间"];
    }
    PostStatsXinli(this, params).then((res: any) => {
      res["居民心理类型情况"]["high"].map((item: any) => {
        item.name = item.title;
      });
      res["居民心理类型情况"]["low"].map((item: any) => {
        item.name = item.title;
      });
      this.data["测评内容统计"]["居民心理类型情况"] = res;
      this.juminBar = {
        legend: {
          data: ["高分人群", "低分人群"],
          right: 0,
        },
        color: ["#74A0FA", "#71DEB5"],
        xAxis: {
          type: "category",
          data: res["居民心理类型情况"].title,
        },
        yAxis: {},
        grid: {
          width: "90%",
          bottom: 40,
        },
        tooltip: {},
        series: [
          {
            name: "高分人群",
            type: "bar",
            data: res["居民心理类型情况"].high,
          },
          {
            name: "低分人群",
            type: "bar",
            data: res["居民心理类型情况"].low,
          },
        ],
      };
    });
  }
  /**
   * @description 人口统计
   */
  private getStatsPopulation() {
    const ids: any = [];
    this.selectedHospitals.forEach((h: any) => {
      ids.push(h._id);
    });
    const doctors: any = [];
    this.selectedDoctors.forEach((h: any) => {
      doctors.push(h.doctor_id);
    });
    const params: any = {
      hospital_ids: ids,
      doctor_id: doctors,
    };
    PostStatsPopulation(this, params).then((res: any) => {
      res["年龄分布"]["data"].map((item: any) => {
        item.name = item.title;
      });
      res["性别分布"]["男"] = res["性别分布"]["data"][0]["ratio"];
      res["性别分布"]["女"] = res["性别分布"]["data"][1]["ratio"];
      this.data["人口统计"] = res;
      this.ageOptions = {
        color: this.pieColors,
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          top: 0,
          left: "60",
          right: "60",
          bottom: "20",
          containLabel: false,
        },
        xAxis: {
          show: false,
        },
        yAxis: {
          axisLine: {
            // y轴
            show: false,
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            // 网格线
            show: false,
          },
          type: "category",
          data: res["年龄分布"].title,
        },
        series: [
          {
            name: "数量",
            type: "bar",
            data: res["年龄分布"].data,
            label: {
              show: true,
              position: "right",
              color: "#333",
              formatter: (pa: any) => {
                return (pa.data.ratio * 100).toFixed(2) + "%";
              },
            },
          },
        ],
      };
    });
  }
  private refresh() {
    if (this.$store.state.user["姓名"] !== "admin") {
      this.getEva();
    }
    this.getAll();
    this.getStatusMouth();
    this.getStatsPeople();
    this.getStatsTizhi();
    this.getStatsNeirong();
    this.getStatsZhengxing();
    this.getStatsXinli();
    this.getStatsPopulation();
    this.getStatsHospital();
  }
  private mounted() {
    // this.fetchHospitals();
    this.refresh();
  }
}
